import NiceModal, { useModal } from '@ebay/nice-modal-react'
import {
  WppActionButton,
  WppButton,
  WppIconGear,
  WppIconRemoveCircle,
  WppTypography,
} from '@platform-ui-kit/components-library-react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Avatar } from 'components/common/avatar/Avatar'
import { Flex } from 'components/common/flex/Flex'
import { SideModal } from 'components/surface/sideModal/SideModal'
import { Members } from 'types/members/members'
import { fullName } from 'utils/common'

interface Props {
  hiddenFrom: Members[]
  name: string
  onChangeHiddenFrom?: (hiddenFrom: string[]) => void
  showSettingsModal: () => void
}

const HiddenFromMembersModal = NiceModal.create(
  ({ hiddenFrom = [], name, onChangeHiddenFrom, showSettingsModal }: Props) => {
    const modal = useModal()
    const { t } = useTranslation()
    const [hiddenFromMembers, setHiddenFromMembers] = useState<Members[]>(hiddenFrom)

    const handleSettingsClick = () => {
      modal.hide()
      showSettingsModal()
    }

    return (
      <SideModal
        open={modal.visible}
        onWppSideModalClose={modal.hide}
        onWppSideModalCloseComplete={modal.remove}
        size="m"
        data-testid="hide-from-members-modal"
      >
        <>
          <WppTypography slot="header" type="2xl-heading" data-testid="app-name">
            {t('modals.hidden_from_members_modal.title')}
          </WppTypography>

          <Flex slot="body" direction="column" gap={12}>
            <>
              <WppTypography type="xl-heading">{name}</WppTypography>
              {hiddenFromMembers.map(user => (
                <Flex justify="between" align="center" key={user.id} style={{ width: '100%' }}>
                  <Flex gap={6} align="center">
                    <Avatar size="xs" name={fullName(user.firstname, user.lastname)} src={user.avatarUrl || ''} />
                    <Flex direction="column">
                      <WppTypography type="s-body">{fullName(user.firstname, user.lastname)}</WppTypography>
                      <WppTypography type="s-body">{user.email}</WppTypography>
                    </Flex>
                  </Flex>

                  <WppActionButton
                    variant="secondary"
                    data-testid="remove-member"
                    onClick={() => {
                      const newHiddenFrom = hiddenFromMembers.filter(m => m.id !== user.id)
                      setHiddenFromMembers(newHiddenFrom)
                      onChangeHiddenFrom?.(newHiddenFrom.map(m => m.id))
                    }}
                  >
                    <WppIconRemoveCircle slot="icon-end" />
                  </WppActionButton>
                </Flex>
              ))}
            </>
          </Flex>

          <Flex slot="actions" justify="between" gap={12}>
            <WppActionButton onClick={handleSettingsClick} data-testid="settings-action" variant="primary">
              <WppIconGear slot="icon-start" />
              {t('project.item.settings')}
            </WppActionButton>
            <WppButton variant="primary" size="m" onClick={modal.hide} data-testid="button-done">
              {t('common.btn_done')}
            </WppButton>
          </Flex>
        </>
      </SideModal>
    )
  },
)

export default HiddenFromMembersModal
