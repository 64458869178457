import { WppButton, WppPill, WppTag, WppTypography } from '@platform-ui-kit/components-library-react'
import { FormProvider } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import * as zod from 'zod'

import { PatchPhase } from 'api/canvas/fetchers/patchPhaseApi'
import { usePatchPhaseApi } from 'api/canvas/mutation/usePatchPhaseApi'
import { Avatar } from 'components/common/avatar/Avatar'
import { Flex } from 'components/common/flex/Flex'
import { FormDatepicker } from 'components/form/formDatepicker/FormDatepicker'
import { FormInput } from 'components/form/formInput/FormInput'
import { FormSelect } from 'components/form/formSelect/FormSelect'
import { FormTextareaInput } from 'components/form/formTextareaInput/FormTextareaInput'
import { MultipleSelectWithPill, TagProps } from 'components/form/multipleSelectWithPill/MultipleSelectWithPill'
import { SideModal } from 'components/surface/sideModal/SideModal'
import { CanvasItemType } from 'constants/analytics'
import { useForm } from 'hooks/form/useForm'
import useEventSource from 'hooks/useEventSource'
import { useProject } from 'hooks/useProject'
import { useToast } from 'hooks/useToast'
import useWorkflowEditAnalytics from 'hooks/useWorkflowEditAnalytics'
import styles from 'pages/project/components/canvas/components/editPhaseModal/EditPhaseModal.module.scss'
import { SelectOsGroupForm } from 'pages/project/components/canvas/components/selectOsGroup/SelectOsGroupForm'
import { SelectPerson } from 'pages/project/components/canvas/components/selectPerson/SelectPerson'
import {
  ResponsibleUser,
  useGetBaseFormValues,
  getFormattedDates,
} from 'pages/project/components/canvas/components/selectPerson/utils'
import { invalidateCanvas } from 'pages/project/components/canvas/linearCanvas/components/item/utils'
import { LinearPhase } from 'pages/project/components/canvas/utils'
import { createProjectModal } from 'pages/project/utils/createProjectModal'
import { Members } from 'types/members/members'
import { ProjectRole } from 'types/permissions/permissions'
import { PhaseStatus } from 'types/projects/workflow'
import { COMMON_VALIDATION, fullName, makeStringShorter } from 'utils/common'
import { NiceModalWrappedProps } from 'utils/createNiceModal'

interface Props extends NiceModalWrappedProps {
  phase: LinearPhase
  isDisabled?: boolean
  projectId: string
  isWrikeConnected?: boolean
  isTemplate?: boolean
}

const phaseValidationSchema = zod
  .object({
    name: zod
      .string()
      .min(1, 'Name should contain at least 1 character')
      .max(256, 'Name should have 256 characters at most'),
    description: zod.string().max(1000, 'Description should have 1000 characters at most').optional(),
  })
  .passthrough()

const EditPhaseModal = ({
  phase,
  onClose,
  onCloseComplete,
  isOpen,
  isDisabled,
  projectId,
  isWrikeConnected,
  isTemplate,
}: Props) => {
  const { showToast } = useToast()
  const { t } = useTranslation()

  const projectContext = useProject()
  const { trackItemEditCancelled, trackItemEdit } = useWorkflowEditAnalytics()
  const eventSource = useEventSource()

  const defaultValues = {
    ...phase,
    description: phase.description || '',
    ...useGetBaseFormValues(phase, projectId),
    status: phase.status,
    ...(isTemplate && { groupId: phase?.group?.id || null }),
    hideFromMembers: phase.hiddenFromAll ? projectContext?.project?.members : phase.hiddenFrom || [],
  }

  const form = useForm({ defaultValues, validationSchema: phaseValidationSchema })

  const { mutateAsync: handleUpdatePhase } = usePatchPhaseApi()

  const {
    handleSubmit,
    formState: { isSubmitting },
    watch,
  } = form

  const { hideFromMembers } = watch()

  const onSubmit = handleSubmit(
    async ({ dueDate, description, name, assignUser: assignedUsers, status, groupId, hideFromMembers }) => {
      try {
        const assignUser = assignedUsers[0] as ResponsibleUser | undefined
        const isHiddenFromAll = hideFromMembers?.length === projectContext?.project?.members?.length

        let updateBody: PatchPhase = {
          phaseId: phase.id,
          name: name.trim(),
          description: description?.trim() || null,
        }

        // other fields are not allowed for templates
        if (!isTemplate) {
          updateBody = {
            ...updateBody,
            ...getFormattedDates(dueDate),
            assignUser: assignUser?.email || null,
            status,
            ...(!isHiddenFromAll &&
              hideFromMembers && {
                hideFromMembers: hideFromMembers.map(member => member.id) ?? [],
              }),
            hideFromAll: isHiddenFromAll,
          }
        }
        // other fields are allowed only in templates
        if (isTemplate) {
          updateBody = {
            ...updateBody,
            groupId: groupId || null,
          }
        }

        await handleUpdatePhase(updateBody)

        await invalidateCanvas(!assignUser?.isMember)
        showToast({
          type: 'success',
          message: t('project.canvas.toast.update_phase', { query: makeStringShorter(name) }),
        })

        if (projectContext?.project) {
          trackItemEdit({
            type: CanvasItemType.PHASE,
            assignUser,
            eventSource,
          })
        }

        onClose()
      } catch (e) {
        showToast({
          type: 'error',
          message: t('project.canvas.toast.failed_operation_edit', { query: 'phase' }),
        })
        console.log(e)
      }
    },
  )

  const handleCancelEdit = () => {
    onClose()

    if (projectContext) {
      trackItemEditCancelled(CanvasItemType.PHASE, eventSource)
    }
  }

  const getTagProps = (member: Members): TagProps | undefined => {
    if (member.role === ProjectRole.OWNER) {
      return { label: t('common.owner'), categoricalColorIndex: 1 } // Return the tag props for owners
    }
    if (member.email === phase.assignUser) {
      return { label: t('common.assignee'), variant: 'neutral' } // Return the tag props for assignee
    }
    return undefined
  }

  const isHiddenFromAssigneeOrOwner = hideFromMembers?.some(
    member => member.email === phase.assignUser || member.role === ProjectRole.OWNER,
  )

  return (
    <FormProvider {...form}>
      <SideModal
        open={isOpen}
        formConfig={{ onSubmit }}
        onWppSideModalClose={handleCancelEdit}
        onWppSideModalCloseComplete={onCloseComplete}
        size="m"
      >
        <WppTypography slot="header" type="2xl-heading">
          {t('modals.settings.phase_settings')}
        </WppTypography>
        <Flex slot="body" direction="column" gap={24}>
          <FormInput
            name="name"
            labelConfig={{ text: t('project.phase.settings_modal.name_label') }}
            placeholder={t('project.phase.settings_modal.name_placeholder')}
            required
            disabled={isDisabled}
            data-testid="modal-phase-name"
          />
          <FormTextareaInput
            name="description"
            labelConfig={{ text: t('project.phase.settings_modal.description_label') }}
            placeholder={t('project.phase.settings_modal.description_placeholder')}
            warningThreshold={COMMON_VALIDATION.description.warning}
            charactersLimit={COMMON_VALIDATION.description.max}
            disabled={isDisabled}
            data-testid="modal-phase-description"
          />

          {!isTemplate && (
            <>
              <SelectPerson disabled={isDisabled} projectId={projectId} isWrikeConnected={isWrikeConnected} />
              <FormDatepicker
                className={styles.datePicker}
                name="dueDate"
                placeholder={t('project.phase.settings_modal.dates_placeholder')}
                labelConfig={{ text: t('project.phase.settings_modal.dates_label') }}
                range
                disabled={isDisabled}
              />
              <FormSelect
                name="status"
                required
                labelConfig={{ text: 'Status' }}
                options={Object.values(PhaseStatus).map(status => ({
                  label: t(`project.phase.status.${status}`),
                  value: status,
                }))}
                data-testid="modal-phase-status"
              />

              {!!projectContext && (
                <>
                  <MultipleSelectWithPill
                    name="hideFromMembers"
                    options={projectContext.project.members!}
                    type="multiple"
                    labelConfig={{ text: t('modals.app_details_modal.hide_from_members') }}
                    placeholder={t('modals.app_details_modal.hide_from_members_placeholder')}
                    withSearch
                    infoMessage={
                      isHiddenFromAssigneeOrOwner ? t('modals.app_details_modal.hide_from_members_info') : undefined
                    }
                    dropdownConfig={{ placement: 'top' }}
                    getKey={member => member.id}
                    renderItem={({ option: member }) => (
                      <>
                        <span slot="label">{fullName(member.firstname, member.lastname)}</span>
                        <span slot="caption">{member.email}</span>

                        <WppTag
                          slot="right"
                          label={getTagProps(member)?.label}
                          variant={getTagProps(member)?.variant}
                          categoricalColorIndex={getTagProps(member)?.categoricalColorIndex}
                        />
                      </>
                    )}
                    renderPill={({ option: member, onDeselect }) => (
                      <WppPill
                        size="m"
                        type="display"
                        key={member.id}
                        className={styles.pill}
                        removable
                        onWppClose={onDeselect}
                      >
                        <Flex align="center">
                          <Avatar
                            size="xs"
                            name={fullName(member.firstname, member.lastname)}
                            src={member.avatarUrl || ''}
                            style={{ marginRight: '6px' }}
                          />
                          {fullName(member.firstname, member.lastname)}
                        </Flex>
                      </WppPill>
                    )}
                  />
                </>
              )}
            </>
          )}

          {isTemplate && <SelectOsGroupForm data-testid="modal-phase-group" initialValue={phase?.group} />}
        </Flex>
        <Flex slot="actions" justify="end" gap={12}>
          <WppButton variant="secondary" size="m" onClick={handleCancelEdit}>
            {t('common.btn_cancel')}
          </WppButton>
          <WppButton variant="primary" size="m" type="submit" loading={isSubmitting} disabled={isDisabled}>
            {t('common.btn_save')}
          </WppButton>
        </Flex>
      </SideModal>
    </FormProvider>
  )
}
export const { showModal: showEditPhaseModal } = createProjectModal<Props>(EditPhaseModal, 'edit-phase-modal')
