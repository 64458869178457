import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { PINBOARD_URL } from 'constants/pinboard-config'
import { useProject } from 'hooks/useProject'
import { PenpalParentFrame } from 'pages/project/components/penpalParentFrame/PenpalParentFrame'
import styles from 'pages/project/components/pinboard/Pinboard.module.scss'
import { routesManager } from 'utils/routesManager'

export const Pinboard = () => {
  const { t } = useTranslation()
  const { project } = useProject()
  const parentUrl = useMemo(() => {
    return `orchestration${routesManager.project.pinboard.getURL({
      id: project.id,
    })}`
  }, [project.id])

  return (
    <PenpalParentFrame
      className={styles.iframeRoot}
      src={PINBOARD_URL}
      title={t('pinboard.title')}
      parentUrl={parentUrl}
    />
  )
}
